import { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from './GlobalContext';

export const AccountContext = createContext(null);

export const AccountProvider = ({ children }) => {
	const BASE_URL = process.env.REACT_APP_BASE_URL;
	const STORE_ID = process.env.REACT_APP_STORE_ID;

	// SWAL ERROR VALIDATION
	const Toast = Swal.mixin({
		toast: true,
		position: 'top',
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true,
		didOpen: (toast) => {
			toast.addEventListener('mouseenter', Swal.stopTimer);
			toast.addEventListener('mouseleave', Swal.resumeTimer);
		},
	});

	const navigate = useNavigate();

	// USER LOGGED IN STATE
	const [isLoggedIn, setLogin] = useState();
	const [change, setChange] = useState(false);

	// LOGGED USER DATA
	const [loggedUserData, setLoggedUserData] = useState(
		JSON.parse(window.localStorage.getItem('loggedUserData'))
	);

	// use effect to setLogin state so we can handle the behavior of the login button/page/form
	useEffect(() => {
		if (localStorage.getItem('loggedUserData') !== null) {
			if (localStorage.getItem('toExpire') !== null && localStorage.getItem('toExpire') <= JSON.stringify(new Date())) {
				setLogin(false);
				logoutHandler();
			} else {
				setLogin(true);
				let auth = JSON.parse(localStorage.getItem('loggedUserData'));
				setLoggedUserData(auth);
				listStudents(auth);
				listCarrinho(auth);
				listSalesHistory(auth);
				loadVinculacao();
			}
		} else {
			setLogin(false);
		}
	}, [change]); // eslint-disable-line react-hooks/exhaustive-deps

	// REGISTRATION FORM STATE
	const [account, setAccount] = useState('');
	// LOGIN MODAL STATE
	const [isOpen, setIsOpen] = useState(false);
	const [opacity, setOpacity] = useState(0);

	function addMinutes(date, minutes) {
		date.setMinutes(date.getMinutes() + minutes);

		return date;
	}

	// LISTAR ESTUDANTES VINCULADOS AO LOGAR
	function listStudents(responseFromLogin) {
		const optionsListStudents = {
			method: 'GET',
			url: `${BASE_URL}/students/list-students-by-customer/${responseFromLogin.customer.id}/store/${STORE_ID}`,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${responseFromLogin.token}`,
			},
			validateStatus: () => true,
		};

		axios
			.request(optionsListStudents)
			.then(function (response) {
				if (response.status === 401)
					tokenExpireLogout(response)
				else
					setStudentList(response.data.students);
			})
			.catch(function (error) {
				console.error(error);
			});
	}

	const { setCarrinho, setCartAffiliate, loadVinculacao, setSalesHistory } =
		useContext(GlobalContext);

	// LISTAR CARRINHO AO LOGAR
	function listCarrinho(responseFromLogin) {
		const optionsListCarrinho = {
			method: 'GET',
			url: `${BASE_URL}/carts/list-cart-by-customer/${responseFromLogin.customer.id}/store/${STORE_ID}`,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${responseFromLogin.token}`,
			},
			validateStatus: () => true,
		};

		axios
			.request(optionsListCarrinho)
			.then(function (response) {
				if (response.status === 401) {
					tokenExpireLogout(response)
				} else {
					let responseBags;
					let cartAffiliate;
					if (response.data.cart) {
						responseBags = response.data.cart.bags.map((bag) => ({
							cartId: response.data.cart.id,
							name: bag.name,
							studentId: bag.studentId,
							cartItems: bag.cartItems.map((cartItem) => ({
								itemCouponId: cartItem.itemCouponId,
								itemCoupon: cartItem.itemCoupon,
								itemTOTVSId: cartItem.item.itemTOTVSId,
								quantity: cartItem.quantity,
								PRECO: cartItem.item.PRECO,
								DESCONTO: cartItem.item.DESCONTO,
								TIPO: cartItem.item.TIPO,
								NOME: cartItem.item.NOME,
								TURNO: cartItem.item.TURNO,
								PARCELAS: cartItem.item.PARCELAS,
								CODCOLIGADA: cartItem.item.CODCOLIGADA,
								cartItemid: cartItem.cartItemid,
								paymentPlan: cartItem.paymentPlan,
								productId: cartItem.product.productId,
								productName: cartItem.product.title,
								productSlug: cartItem.product.slug,
								productImage: cartItem.product.image,
								category: cartItem.product.category,
								affiliateId: response.data.cart.affiliateId
							})),
						}));
						cartAffiliate = response.data.cart.affiliateId;
					} else {
						responseBags = [];
						cartAffiliate = "";
					}
					setCarrinho(responseBags);
					localStorage.setItem('carrinho', JSON.stringify(responseBags));
					setCartAffiliate(cartAffiliate);
					localStorage.setItem('cartAffiliate', JSON.stringify(cartAffiliate));
				}
			})
			.catch(function (error) {
				console.error(error);
				const responseBags = [];
				const cartAffiliate = '';
				setCarrinho(responseBags);
				localStorage.setItem('carrinho', JSON.stringify(responseBags));
				setCartAffiliate(cartAffiliate);
				localStorage.setItem('cartAffiliate', JSON.stringify(cartAffiliate));
			});
	}

	// LOAD CARRINHO AO LOGAR (ONLY RUNS ONCE ON COMPONENT MOUNT and WHEN REFRESH IS CALLED)
	useEffect(() => {
		loadVinculacao();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// LISTAR HISTÓRICO DE PEDIDOS AO LOGAR
	function listSalesHistory(responseFromLogin) {
		const optionsListSalesHistory = {
			method: 'GET',
			url: `${BASE_URL}/sales/list-sale-by-customer-id/${responseFromLogin.customer.id}/store/${STORE_ID}`,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${responseFromLogin.token}`,
			},
			validateStatus: () => true,
		};

		axios
			.request(optionsListSalesHistory)
			.then(function (response) {
				if (response.status === 401) {
					tokenExpireLogout(response)
				} else {
					setSalesHistory(response.data.sales);
					localStorage.setItem(
						'salesHistory',
						JSON.stringify(response.data.sales)
					);
				}
			})
			.catch(function (error) {
				console.error(error);
			});
	}

	// ATUALIZAR HISTÓRICO DE PEDIDOS AO ENTRAR NA PÁGINA DE CONTA
	function updateSalesHistory() {
		const optionsListSalesHistory = {
			method: 'GET',
			url: `${BASE_URL}/sales/list-sale-by-customer-id/${loggedUserData?.customer.id}/store/${STORE_ID}`,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userToken}`,
			},
			validateStatus: () => true,
		};

		axios
			.request(optionsListSalesHistory)
			.then(function (response) {
				if (response.status === 401) {
					tokenExpireLogout(response)
				} else {
					setSalesHistory(response.data.sales);
					localStorage.setItem(
						'salesHistory',
						JSON.stringify(response.data.sales)
					);
				}
			})
			.catch(function (error) {
				console.error(error);
			});
	}

	// LISTAR BAG PELO SALDE ID
	function listBagBySaledId(saleId, setSaleBag) {
		// console.log(saleId);
		setIsLoading(true);
		const optionsListBagBySaleId = {
			method: 'GET',
			url: `${BASE_URL}/sale-bags/list-sale-bag-by-sale-id/${saleId}/store/${STORE_ID}`,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userToken}`,
			},
			validateStatus: () => true,
		};

		axios
			.request(optionsListBagBySaleId)
			.then(function (response) {
				if (response.status === 401) {
					tokenExpireLogout(response)
				} else {

					setSaleBag(response.data.saleBag);
					setIsLoading(false);
					// console.log(response.data.saleBag)
				}
			})
			.catch(function (error) {
				console.error(error);
			});
	}

	// LISTAR BAG PELO SALDE ID
	function listBagBySaleBagId(saleId, saleBagId, setSaleBag) {
		// console.log(saleId);
		const optionsListBagBySaleBagId = {
			method: 'GET',
			url: `${BASE_URL}/sale-bags/check-status-sale-bag/store/${STORE_ID}/sale/${saleId}/saleBag/${saleBagId}`,
			validateStatus: () => true,
		};

		// console.log(optionsListBagBySaleBagId.url);

		axios
			.request(optionsListBagBySaleBagId)
			.then(function (response) {
				setSaleBag(response.data.saleBag);
				// console.log('public bag', response)
			})
			.catch(function (error) {
				console.error(error);
			});
	}

	// LIST STUDENT BY ID
	function listStudentById(id) {
		const optionsListStudentById = {
			method: 'GET',
			url: `${BASE_URL}/students/list-student-by-id`,
			headers: { 'Content-Type': 'application/json' },
			data: {
				storeId: STORE_ID,
				studentId: id,
			},
			validateStatus: () => true,
		};

		axios
			.request(optionsListStudentById)
			.then(function (response) {
				// console.log(response.data);
			})
			.catch(function (error) {
				console.error(error);
			});
	}

	// USER LOGIN API CALL FUNCTION
	function logIn(email, password) {
		setIsLoading(true);
		const optionsLoginUser = {
			method: 'POST',
			url: `${BASE_URL}/accounts/auth-customer`,
			headers: { 'Content-Type': 'application/json' },
			data: {
				email: `${email}`,
				password: `${password}`,
				storeId: `${STORE_ID}`,
			},
			validateStatus: () => true,
		};

		axios
			.request(optionsLoginUser)
			.then(function (response) {
				if (response.status === 200) {
					setChange((change) => !change);
					setLoggedUserData(response.data.auth);
					localStorage.setItem(
						'loggedUserData',
						JSON.stringify(response.data.auth)
					);
					localStorage.setItem('toExpire', JSON.stringify(addMinutes(new Date(), 1440)));
					Toast.fire({
						icon: 'success',
						iconColor: '#fff',
						color: '#fff',
						background: '#55d98d',
						title: `Usuário logado com sucesso!`,
					});
					listStudents(response.data.auth);
					listCarrinho(response.data.auth);
					listSalesHistory(response.data.auth);
					loadVinculacao();
					toggleAccountModal();
				} else {
					Toast.fire({
						icon: 'error',
						iconColor: '#fff',
						color: '#fff',
						background: '#ff6363',
						title: `${response.data.error.message}`,
					});
				}
				setIsLoading(false);
			})
			.catch(function (error) {
				console.error(error);
			});
	}

	// LOGOUT HANDLER
	const logoutHandler = () => {
		localStorage.clear();
		setChange((change) => !change);
		navigate('/');
		navigate(0);
	};

	const tokenExpireLogout = (response) => {
		Toast.fire({
			icon: 'error',
			iconColor: '#fff',
			color: '#fff',
			background: '#ff6363',
			title: "Sua sessão expirou, você será redirecionado para a página principal.",
		})
		setTimeout(() => {
			logoutHandler();
		}, 2000)
	}

	// ESQUECI SENHA
	function forgotPass(email) {
		const optionsForgotPass = {
			method: 'POST',
			url: `${BASE_URL}/customers/reset-password`,
			headers: { 'Content-Type': 'application/json' },
			data: { email: `${email}`, storeId: `${STORE_ID}` },
			validateStatus: () => true,
		};

		// console.log(optionsForgotPass)

		axios
			.request(optionsForgotPass)
			.then(function (response) {
				// console.log(response)
				if (response.status === 200) {
					Toast.fire({
						icon: 'success',
						iconColor: '#fff',
						color: '#fff',
						background: '#55d98d',
						title: `Link de recuperação da senha enviado ao email cadastrado`,
					});
				} else {
					Toast.fire({
						icon: 'error',
						iconColor: '#fff',
						color: '#fff',
						background: '#ff6363',
						title: `${response.data.error.message}`,
					});
				}
			})
			.catch(function (error) {
				console.error(error);
			});
	}

	// ACCOUNT CREATION API CALL FUNCTION
	function signUp(formData, setCurrentStep) {
		const formStep1 = formData.access;
		const formStep2 = formData.register;
		const formStoreId = { storeId: STORE_ID };
		const formCompleted = { ...formStep1, ...formStep2, ...formStoreId };

		const {
			name,
			email,
			password,
			birthDate,
			cellPhone,
			city,
			cpf,
			houseNumber,
			neighborhood,
			state,
			street,
			zipcode,
			storeId,
		} = formCompleted;

		const optionsCreateCustomer = {
			method: 'POST',
			url: `${BASE_URL}/customers/create-customer`,
			headers: { 'Content-Type': 'application/json' },
			data: {
				birthDate: `${birthDate}`,
				cellPhone: `${cellPhone}`,
				city: `${city}`,
				cpf: `${cpf}`,
				email: `${email}`,
				houseNumber: `${houseNumber}`,
				name: `${name}`,
				neighborhood: `${neighborhood}`,
				password: `${password}`,
				state: `${state}`,
				storeId: `${storeId}`,
				street: `${street}`,
				zipcode: `${zipcode}`,
			},
			validateStatus: () => true,
		};

		axios
			.request(optionsCreateCustomer)
			.then(function (response) {
				if (response.status === 200 || response.status === 204) {
					setCurrentStep(3);
					Toast.fire({
						icon: 'success',
						iconColor: '#fff',
						color: '#fff',
						background: '#55d98d',
						title: 'Conta criada com sucesso!',
					});
				} else {
					Toast.fire({
						icon: 'error',
						iconColor: '#fff',
						color: '#fff',
						background: '#ff6363',
						title: `${response.data.error.message}`,
					});
				}
			})
			.catch(function (error) {
				console.error(error);
			});
	}

	const [studentRA, setStudentRA] = useState();
	const userToken = loggedUserData?.token;
	const customerId = loggedUserData?.customer.id;
	const [studentList, setStudentList] = useState(
		JSON.parse(window.localStorage.getItem('studentList'))
	);
	const [refresh, setRefresh] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	// REMOVE STUDENT from customer
	const removeStudentFromUser = (studentId, studentBodyId) => {
		const optionsRemoveStudent = {
			method: 'POST',
			url: `${BASE_URL}/students/soft-delete-link-student-to-customer/${studentBodyId}/student/${studentId}/customer/${customerId}/store/${STORE_ID}`,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userToken}`,
			},
			validateStatus: () => true,
		};

		Swal.fire({
			title: 'Tem certeza?',
			text: 'Esta ação não poderá ser desfeita.',
			icon: 'warning',
			iconColor: '#ff6363',
			showCancelButton: true,
			confirmButtonColor: '#1e3799',
			confirmButtonText: 'Sim, excluir!',
			cancelButtonColor: '#ff6363',
			cancelButtonText: 'Cancelar',
		}).then((result) => {
			if (result.isConfirmed) {
				Toast.fire({
					icon: 'error',
					iconColor: '#fff',
					color: '#fff',
					background: '#ff6363',
					title: 'Vínculo removido!',
				});
				setIsLoading(true);
				axios
					.request(optionsRemoveStudent)
					.then(function (response) {
						if (response.status === 401) {
							tokenExpireLogout(response)
						} else {
							setIsLoading(false);
							setRefresh(!refresh);
							const emptyBags = [];
							setCarrinho(emptyBags);
							localStorage.setItem('carrinho', JSON.stringify(emptyBags));
						}
					})
					.catch(function (error) {
						console.error(error);
					});
			}
		});
	};

	// ADD STUDENT to customer
	const optionsAddStudent = {
		method: 'POST',
		url: `${BASE_URL}/students/add-student-to-customer`,
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${userToken}`,
		},
		data: {
			storeId: `${STORE_ID}`,
			customerId: `${customerId}`,
			RA: `${studentRA}`,
		},
		validateStatus: () => true,
	};

	function handleChangeAddStudent(value) {
		setStudentRA(value);
	}

	let handleSubmitAddStudent = (e) => {
		setIsLoading(true);
		e.preventDefault();
		axios
			.request(optionsAddStudent)
			.then(function (response) {
				// console.log(response);
				if (response.status === 200 || response.status === 204) {
					Toast.fire({
						icon: 'success',
						iconColor: '#fff',
						color: '#fff',
						background: '#55d98d',
						title: `Estudante vinculado(a)!`,
					});
				} else if (response.status === 401) {
					tokenExpireLogout(response);
				} else {
					Toast.fire({
						icon: 'error',
						iconColor: '#fff',
						color: '#fff',
						background: '#ff6363',
						title: `${response.data.error.message}`,
					});
				}

				setIsLoading(false);
				setRefresh(!refresh);
			})
			.catch(function (error) {
				console.error(error);
			});
	};

	// LIST STUDENTS BY CUSTOMER ID (ONLY RUNS ONCE ON COMPONENT MOUNT and WHEN REFRESH IS CALLED)
	// refresh student list when adding/removing students
	useEffect(() => {
		const optionsListStudents = {
			method: 'GET',
			url: `${BASE_URL}/students/list-students-by-customer/${customerId}/store/${STORE_ID}`,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userToken}`,
			},
		};

		loggedUserData &&
			axios
				.request(optionsListStudents)
				.then(function (response) {
					setStudentList(response.data.students);
					localStorage.setItem(
						'studentList',
						JSON.stringify(response.data.students)
					);
				})
				.catch(function (error) {
					if (error.response.status === 401) {
						console.error(error);
						tokenExpireLogout(error.response)
					}
				});
	}, [loggedUserData, refresh]); // eslint-disable-line react-hooks/exhaustive-deps

	// CREATE NEW EMPTY STUDENT FROM FORM
	const [newStudentData, setNewStudentData] = useState('');

	function createNewStudent(formData) {
		const formEstudante = formData.estudante;
		//const formMae = formData.mae;
		const formResponsavel = formData.responsavel;

		// estudante
		const optionsCreateNewStudent = {
			method: 'POST',
			url: `${BASE_URL}/students/create-empty-student`,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userToken}`,
			},
			data: {
				storeId: `${STORE_ID}`,
				customerId: `${customerId}`,
				nome: `${formEstudante.nome}`,
				dtnascimento: `${formEstudante.data_nascimento}`,
				nacionalidade: '10',
				estadonatal: `${formEstudante.estado_natal}`,
				naturalidade: `${formEstudante.cidade_natal}`,
				estadocivil: 'S',
				sexo: 'X',
				cpf: `${formEstudante.cpf}`,
				cep: `${formEstudante.cep}`,
				rua: `${formEstudante.rua}`,
				numero: `${formEstudante.numero}`,
				complemento: 'complemento',
				bairro: `${formEstudante.bairro}`,
				pais: 'Brasil',
				estado: `${formEstudante.estado}`,
				cidade: `${formEstudante.cidade}`,
				email: `${formEstudante.email}`,
				telefone1: `${formEstudante.telefone}`,
			},
			validateStatus: () => true,
		};

		// mãe
		// const optionsAddMaeToNewStudent = {
		// 	method: 'POST',
		// 	url: `${BASE_URL}/responsibles/create-responsible`,
		// 	headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${userToken}` },
		// 	data: {
		// 		storeId: `${STORE_ID}`,
		// 		parentesco: '7',
		// 		tipofiliacao: 'M',
		// 		nome: `${formMae.nome}`,
		// 		dtnascimento: `${formMae.data_nascimento}`,
		// 		nacionalidade: '10',
		// 		estadonatal: `${formMae.estado_natal}`,
		// 		naturalidade: `${formMae.cidade_natal}`,
		// 		estadocivil: 'S',
		// 		sexo: 'F',
		// 		cpf: `${formMae.cpf}`,
		// 		cep: `${formMae.cep}`,
		// 		rua: `${formMae.rua}`,
		// 		numero: `${formMae.numero}`,
		// 		complemento: '',
		// 		bairro: `${formMae.bairro}`,
		// 		pais: 'Brasil',
		// 		estado: `${formMae.estado}`,
		// 		cidade: `${formMae.cidade}`,
		// 		email: `${formMae.email}`,
		// 		telefone1: `${formMae.telefone}`,
		// 		telefone2: `${formMae.telefone}`,
		// 		telefone3: `${formMae.telefone}`
		// 	},
		// 	validateStatus: () => true,
		// };

		// responsável
		const optionsAddResponsToNewStudent = {
			method: 'POST',
			url: `${BASE_URL}/responsibles/create-responsible`,
			headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${userToken}` },
			data: {
				storeId: `${STORE_ID}`,
				parentesco: '9',
				tipofiliacao: 'P',
				nome: `${formResponsavel.nome}`,
				dtnascimento: `${formResponsavel.data_nascimento}`,
				nacionalidade: '10',
				estadonatal: `${formResponsavel.estado_natal}`,
				naturalidade: `${formResponsavel.cidade_natal}`,
				estadocivil: 'S',
				sexo: '',
				cpf: `${formResponsavel.cpf}`,
				cep: `${formResponsavel.cep}`,
				rua: `${formResponsavel.rua}`,
				numero: `${formResponsavel.numero}`,
				complemento: '',
				bairro: `${formResponsavel.bairro}`,
				pais: 'Brasil',
				estado: `${formResponsavel.estado}`,
				cidade: `${formResponsavel.cidade}`,
				email: `${formResponsavel.email}`,
				telefone1: `${formResponsavel.telefone}`,
				telefone2: `${formResponsavel.telefone}`,
				telefone3: `${formResponsavel.telefone}`,
			},
			validateStatus: () => true,
		};

		axios
			.request(optionsCreateNewStudent)
			.then(function (response) {
				if (response.status === 401) {
					tokenExpireLogout(response)
				} else if (response.status !== 200) {
					Toast.fire({
						icon: 'error',
						iconColor: '#fff',
						color: '#fff',
						background: '#ff6363',
						title: `${response.data.error.message}`,
					});
				} else {

					const studentId = response.data.student.id;
					// request mãe
					//optionsAddMaeToNewStudent.data['studentId'] = `${studentId}`;
					//console.log("Data mãe:", optionsAddMaeToNewStudent.data)
					// axios
					// 	.request(optionsAddMaeToNewStudent)
					// 	.then(function (response) {
					// 		if (response.status === 401) {
					// 			tokenExpireLogout(response)
					// 		} else {
					// request responsável
					optionsAddResponsToNewStudent.data['studentId'] = `${studentId}`;
					axios
						.request(optionsAddResponsToNewStudent)
						.then(function (response) {
							if (response.status === 200) {
								Toast.fire({
									icon: 'success',
									iconColor: '#fff',
									color: '#fff',
									background: '#55d98d',
									title: 'Estudante criado(a) com sucesso!',
								});
							} else if (response.status === 401) { tokenExpireLogout(response) } else {
								Toast.fire({
									icon: 'error',
									iconColor: '#fff',
									color: '#fff',
									background: '#ff6363',
									title: `${response.data.error.message}`,
								});
							}
							setRefresh(!refresh);

						})
						.catch(function (error) {
							//console.log("Error Mãe:", error)
							console.error(error);
						});
				}
			})
			.catch(function (error) {
				//console.log("Error Estudante:", error)
				console.error(error);
			});
	}

	// EDITAR DADOS DO CUSTOMER
	const [editAccountData, setEditAccountData] = useState('');

	// FUNÇÃO PRA ALTERAR DADOS
	function handleEditAcesso(formData) {
		const handleEditAcesso = {
			method: 'PUT',
			url: `${BASE_URL}/customers/update-customers-password/${customerId}/store/${STORE_ID}`,
			data: {
				currentPassword: formData.meusDados.senha_atual,
				newPassword: formData.meusDados.nova_senha,
				passwordConfirmation: formData.meusDados.senha_confirm,
			},
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userToken}`,
			},
			validateStatus: () => true,
		};

		axios
			.request(handleEditAcesso)
			.then(function (response) {
				if (response.status === 200 || response.status === 204) {
					Toast.fire({
						icon: 'success',
						iconColor: '#fff',
						color: '#fff',
						background: '#55d98d',
						title: `Senha alterada com sucesso!`,
					});
				} else if (response.status === 401) { tokenExpireLogout(response) } else {
					Toast.fire({
						icon: 'error',
						iconColor: '#fff',
						color: '#fff',
						background: '#ff6363',
						title: `${response.data.error.message}`,
					});
				}
			})
			.catch(function (error) {
				console.error(error);
			});
	}

	function handleEditDados(formData) {
		// console.log(formData);
		const numeroCasa = Number(formData.minhaInfo.houseNumber);
		const optionsEditDados = {
			method: 'PUT',
			url: `${BASE_URL}/customers/update-customer/${customerId}/store/${STORE_ID}`,
			data: {
				name: formData.minhaInfo.name,
				cellPhone: formData.minhaInfo.cellPhone,
				zipcode: formData.minhaInfo.zipcode,
				street: formData.minhaInfo.street,
				houseNumber: numeroCasa,
				neighborhood: formData.minhaInfo.neighborhood,
				city: formData.minhaInfo.city,
				state: formData.minhaInfo.state,
				birthDate: formData.minhaInfo.birthDate,
				cpf: formData.minhaInfo.cpf,
			},
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userToken}`,
			},
			validateStatus: () => true,
		};

		// console.log(optionsEditDados);

		axios
			.request(optionsEditDados)
			.then(function (response) {
				// console.log(response.data);
				if (response.status === 200 || response.status === 204) {
					Toast.fire({
						icon: 'success',
						iconColor: '#fff',
						color: '#fff',
						background: '#55d98d',
						title: `Dados alterados com sucesso!`,
					});
					const { customer, token } = JSON.parse(localStorage.getItem('loggedUserData'))
					localStorage.setItem('loggedUserData', JSON.stringify({ customer: { ...customer, ...formData.minhaInfo }, token }))
					setTimeout(() => { navigate(0) }, 2000)
				} else if (response.status === 401) { tokenExpireLogout(response) } else {
					Toast.fire({
						icon: 'error',
						iconColor: '#fff',
						color: '#fff',
						background: '#ff6363',
						title: `${response.data.error.message}`,
					});
				}
			})
			.catch(function (error) {
				console.error(error);
			});
	}

	// LIST TERMOS & CONDIÇõES BY STORE ID
	const [termsContracts, setTermsContracts] = useState(
		JSON.parse(window.localStorage.getItem('termsContracts'))
	);
	useEffect(() => {
		const optionsListTermos = {
			method: 'GET',
			url: `${BASE_URL}/stores/list-store-by-store-id/${STORE_ID}`,
		};

		axios
			.request(optionsListTermos)
			.then(function (response) {
				const termos = response.data.store.termsContracts;
				setTermsContracts(termos);
				localStorage.setItem('termsContracts', JSON.stringify(termos));
			})
			.catch(function (error) {
				console.error(error);
			});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// MODAL ACTIONS
	function toggleAccountModal() {
		setOpacity(0);
		setIsOpen(!isOpen);
	}

	function afterOpen() {
		setTimeout(() => {
			setOpacity(1);
		}, 100);
	}

	function beforeClose() {
		return new Promise((resolve) => {
			setOpacity(0);
			setTimeout(resolve, 300);
		});
	}

	// TRUNCATE TEXT - CUT TEXT SHORT
	function truncateText(text, length) {
		if (text.length <= length) {
			return text;
		}

		return text.substr(0, length) + '\u2026';
	}

	const value = {
		handleEditDados,
		account,
		setAccount,
		isOpen,
		setIsOpen,
		opacity,
		setOpacity,
		toggleAccountModal,
		afterOpen,
		beforeClose,
		signUp,
		Toast,
		loggedUserData,
		setLoggedUserData,
		isLoggedIn,
		setLogin,
		change,
		setChange,
		BASE_URL,
		STORE_ID,
		handleChangeAddStudent,
		handleSubmitAddStudent,
		setStudentRA,
		studentList,
		setStudentList,
		removeStudentFromUser,
		refresh,
		setRefresh,
		truncateText,
		newStudentData,
		setNewStudentData,
		createNewStudent,
		isLoading,
		customerId,
		userToken,
		logIn,
		logoutHandler,
		tokenExpireLogout,
		updateSalesHistory,
		listBagBySaledId,
		forgotPass,
		editAccountData,
		setEditAccountData,
		handleEditAcesso,
		termsContracts,
		listBagBySaleBagId,
		listStudentById,
	};
	return (
		<AccountContext.Provider value={value}>{children}</AccountContext.Provider>
	);
};
